import React from 'react'
import { css } from '@emotion/react'
import { FacebookIcon, LinkedinIcon, TwitterIcon } from '../atoms/SocialIcon'
import { colors } from 'plume-ui'

export const SNS: React.FC<{ className?: string }> = (props) => {
  return (
    <ul
      css={css`
        margin: 0;
        padding: 0;
        display: flex;
        column-gap: 8px;
        li {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            aspect-ratio: 1 / 1;
            :hover {
              text-decoration: none;
              opacity: 0.8;
            }
          }
        }
      `}
      className={props.className}
    >
      <li>
        <a
          href="https://twitter.com/speeda_ub"
          target="_blank"
          data-testid="twitter"
        >
          <TwitterIcon size={24} color={colors.white00} />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/ub.speeda"
          target="_blank"
          data-testid="facebook"
        >
          <FacebookIcon size={24} color={colors.white00} />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/showcase/speeda/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedinIcon size={24} color={colors.white00} />
        </a>
      </li>
    </ul>
  )
}
