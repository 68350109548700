import * as React from 'react'

type IcomoonIconName =
  | 'alert-fill'
  | 'arrow-back'
  | 'arrow-right-circle-fill'
  | 'arrow-right-up'
  | 'arrow1-down'
  | 'arrow1-left'
  | 'arrow1-right'
  | 'arrow1-up'
  | 'arrow1s-down'
  | 'arrow4-left'
  | 'arrow4-right'
  | 'arrow7-down'
  | 'arrow7-right'
  | 'arrow8-down'
  | 'arrow8-up'
  | 'bell'
  | 'box-drawing-character'
  | 'calendar'
  | 'check-circle-fill'
  | 'check'
  | 'check1'
  | 'close-panel'
  | 'close'
  | 'close1'
  | 'close2'
  | 'dots-vertical'
  | 'download'
  | 'facebook-circle-fill'
  | 'facebook'
  | 'flag1'
  | 'gear'
  | 'headphone-fill'
  | 'info2'
  | 'initial-circle'
  | 'investor'
  | 'ipo'
  | 'link'
  | 'linkedin-square-fill'
  | 'lock-fill'
  | 'memo'
  | 'menu'
  | 'minus-square-fill'
  | 'more-horizotal'
  | 'newspicks'
  | 'pen-fill'
  | 'people-fill'
  | 'people2'
  | 'person-fill'
  | 'person'
  | 'plus-square-fill'
  | 'plus'
  | 'question-circle-fill'
  | 'question-circle'
  | 'remove-fill'
  | 'search'
  | 'side-panel'
  | 'sort-fill'
  | 'sort1'
  | 'spot1'
  | 'startup'
  | 'tag'
  | 'trash-fill'
  | 'twitter'
  | 'unlock-fill'
  | 'x'

type Props = {
  name: IcomoonIconName
  className?: string
  label?: string
  'data-appcues'?: string
  'data-test-id'?: string
}

export const IcomoonIcon: React.FC<Props> = ({
  name,
  className,
  label,
  ...rest
}: Props) => {
  return (
    <i
      className={`icon-${name} ${className}`}
      aria-label={label}
      data-appcues={rest['data-appcues']}
      data-test-id={rest['data-test-id']}
    />
  )
}

type ClickableProps = {
  onClick: (e?: any) => void
}

export const ClickableIcomoonIcon: React.FC<Props & ClickableProps> = ({
  name,
  className,
  onClick,
  ...rest
}) => {
  return (
    <div onClick={onClick}>
      <IcomoonIcon name={name} className={className} {...rest} />
    </div>
  )
}
