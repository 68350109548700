import React from 'react'
import { css } from '@emotion/react'
import Link from 'next/link'
import { colors, mq } from 'plume-ui'
import { Logo } from './Logo'
import {
  privacyPolicyPath,
  privacyPolicyOfUzabaseUrl,
  extModulesPath,
  securityPolicyOfUzabaseUrl,
  touGuestPath,
  trademarkPath,
  operatingCompanyUrl,
  downloadDocumentUrl,
  trialRequestUrl,
  enterpriseUrl,
  speedaBusinessInsightsTopUrl,
  speedaInnovationInsightsTopUrl,
  speedaRandDInsightsTopUrl,
  speedaCustomerAnalyticsTopUrl,
  operatingCompanyCareersUrl,
  operatingCompanyInformationUrl,
  resourcesUrl,
  customerInterviewsUrl,
  seminarsUrl,
} from '@/lib/url'
import { SNS } from './SNS'

type Props = {
  className?: string
  noBorder?: boolean
}

export const Footer: React.FC<Props> = (props) => {
  return (
    <footer
      id="footer"
      css={css`
        background-color: ${colors.black20};
        display: flex;
        flex-direction: column;
        align-items: center;
        ${props.noBorder ? '' : `border-top: 1px solid ${colors.white00};`}
        color: ${colors.white00};
      `}
      className={props.className}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          width: calc(100% - 48px);
          max-width: 1120px;
          padding-block: 40px 0;
          ${mq.large} {
            column-gap: 40px;
            flex-direction: row;
            justify-content: space-between;
            width: calc(100% - 40px);
            padding-block: 48px 40px;
          }
        `}
      >
        <div
          css={css`
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 16px;
          `}
        >
          <p
            css={css`
              margin: 0;
            `}
          >
            <Link href="/" passHref>
              <a>
                <Logo mode="dark" />
              </a>
            </Link>
          </p>
          <SNS />
        </div>
        <nav
          css={css`
            display: flex;
            flex-direction: column;
            ${mq.large} {
              flex-direction: row;
              flex-wrap: wrap;
              gap: 64px min(4vw, 64px);
            }
          `}
        >
          <FooterMenu>
            <FooterMenuTitle>スピーダについて</FooterMenuTitle>
            <FooterMenuLink>
              <Link href={enterpriseUrl} passHref>
                <a target="_blank">スピーダとは</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={customerInterviewsUrl} passHref>
                <a target="_blank">導入事例</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={seminarsUrl} passHref>
                <a target="_blank">セミナー・イベント</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={resourcesUrl} passHref>
                <a target="_blank">資料・レポート</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={downloadDocumentUrl} passHref>
                <a target="_blank">資料ダウンロード</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={trialRequestUrl} passHref>
                <a target="_blank">無料トライアル</a>
              </Link>
            </FooterMenuLink>
          </FooterMenu>
          <FooterMenu>
            <FooterMenuTitle>プロダクト</FooterMenuTitle>
            <FooterMenuLink>
              <Link href={speedaBusinessInsightsTopUrl} passHref>
                <a target="_blank">経済情報リサーチ</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href="/" passHref>
                <a>スタートアップ情報リサーチ</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={speedaInnovationInsightsTopUrl} passHref>
                <a target="_blank">イノベーション情報リサーチ</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={speedaRandDInsightsTopUrl} passHref>
                <a target="_blank">R&D分析</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href={speedaCustomerAnalyticsTopUrl} passHref>
                <a target="_blank">顧客企業分析</a>
              </Link>
            </FooterMenuLink>
          </FooterMenu>
          <FooterMenu>
            <FooterMenuTitle>サポート</FooterMenuTitle>
            <FooterMenuLink>
              <Link href="https://help.initial.inc/" passHref>
                <a target="_blank" rel="noopener noreferrer">
                  ヘルプセンター
                </a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href="/sso/bridge/commmune" passHref>
                <a target="_blank">オンラインコミュニティ</a>
              </Link>
            </FooterMenuLink>
            <FooterMenuLink>
              <Link href="/contact" passHref>
                <a>お問い合わせ</a>
              </Link>
            </FooterMenuLink>
          </FooterMenu>
          <ul
            css={css`
              margin: 0;
              padding: 0;
              display: flex;
              justify-content: center;
              flex-direction: row;
              gap: 16px min(12vw, 40px);
              padding-block: 24px;
              border-top: 1px solid #363636;
              ${mq.large} {
                justify-content: normal;
                flex-direction: column;
                border: 0;
                padding-block: 0;
              }
            `}
          >
            <OperatingCompanyLink>
              <Link href={operatingCompanyUrl} passHref>
                <a target="_blank">運営会社</a>
              </Link>
            </OperatingCompanyLink>
            <OperatingCompanyLink>
              <Link href={operatingCompanyInformationUrl} passHref>
                <a target="_blank">お知らせ</a>
              </Link>
            </OperatingCompanyLink>
            <OperatingCompanyLink>
              <Link href={operatingCompanyCareersUrl} passHref>
                <a target="_blank">採用情報</a>
              </Link>
            </OperatingCompanyLink>
          </ul>
        </nav>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          width: calc(100% - 40px);
          border-top: 1px solid #363636;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            row-gap: 24px;
            width: 100%;
            max-width: 1120px;
            padding-block: 24px 40px;
            ${mq.large} {
              flex-direction: row;
              padding-block: 16px 32px;
              column-gap: 32px;
            }
          `}
        >
          <ul
            css={css`
              margin: 0;
              padding: 0;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              column-gap: 16px;
              row-gap: 8px;
              ${mq.large} {
                justify-content: normal;
                column-gap: 24px;
              }
            `}
            data-testid="footer-bottom-links"
          >
            <OptionLink>
              <Link href={touGuestPath} passHref>
                <a>利用規約</a>
              </Link>
            </OptionLink>
            <OptionLink>
              <Link href={privacyPolicyPath} passHref>
                <a>個人情報保護方針</a>
              </Link>
            </OptionLink>
            <OptionLink>
              <Link href={privacyPolicyOfUzabaseUrl} passHref>
                <a target="_blank">ユーザベース個人情報保護方針</a>
              </Link>
            </OptionLink>
            <OptionLink>
              <Link href={extModulesPath} passHref>
                <a>利用者に関する情報の外部送信について</a>
              </Link>
            </OptionLink>
            <OptionLink>
              <Link href={securityPolicyOfUzabaseUrl} passHref>
                <a target="_blank">情報セキュリティ基本方針</a>
              </Link>
            </OptionLink>
            <OptionLink>
              <Link href={trademarkPath} passHref>
                <a>商標について</a>
              </Link>
            </OptionLink>
          </ul>
          <p
            css={css`
              margin: 0;
              flex-shrink: 0;
            `}
          >
            <small
              css={css`
                color: #cbcbcb;
                font-size: 12px;
                line-height: 1.25;
                letter-spacing: 0.02em;
              `}
            >
              © Uzabase, Inc.
            </small>
          </p>
        </div>
      </div>
    </footer>
  )
}

type FooterMenuProps = {
  children: React.ReactNode
  className?: string
}

const FooterMenu: React.FC<FooterMenuProps> = (props) => {
  return (
    <dl
      css={css`
        margin: 0;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        border-top: 1px solid #363636;
        padding-block: 24px;
        ${mq.large} {
          border: 0;
          padding-block: 0;
        }
      `}
      className={props.className}
    >
      {props.children}
    </dl>
  )
}

const FooterMenuTitle: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <dt
      css={css`
        color: ${colors.white00};
        font-size: 14px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.02em;
      `}
    >
      {props.children}
    </dt>
  )
}

const FooterMenuLink: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <dd
      css={css`
        margin: 0;
        a {
          color: #cbcbcb;
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0.02em;
          &:hover {
            text-decoration: underline;
          }
        }
      `}
    >
      {props.children}
    </dd>
  )
}
const OperatingCompanyLink: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return (
    <li
      css={css`
        a {
          color: ${colors.textInvert};
          font-size: 14px;
          font-weight: bold;
          line-height: 1.25;
          letter-spacing: 0.02em;
        }
      `}
    >
      {props.children}
    </li>
  )
}

const OptionLink: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <li
      css={css`
        a {
          color: #cbcbcb;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: 0.02em;
          &:hover {
            text-decoration: underline;
          }
        }
      `}
    >
      {props.children}
    </li>
  )
}
