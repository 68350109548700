import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { IcomoonIcon } from '../atoms/IcomoonIcon'
import Link from 'next/link'
import { Logo } from '../common/Logo'
import {
  customerInterviewsUrl,
  downloadDocumentUrl,
  enterpriseUrl,
  loginPath,
  resourcesUrl,
  seminarsUrl,
  trialRequestUrl,
} from '@/lib/url'
import { SNS } from '../common/SNS'
import { EnterpriseButton } from '../pages/enterprise/EnterpriseButton'

type MobileSideMenuProps = {
  isOpen: boolean
  toggleIsOpen: () => void
  pageType: 'product' | 'enterprise'
}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = (props) => {
  let logoLink: string
  let logoBlank: string
  switch (props.pageType) {
    case 'enterprise':
      logoLink = enterpriseUrl
      logoBlank = '_blank'
      break
    default:
      logoLink = '/'
      logoBlank = '_top'
      break
  }
  return (
    <nav
      css={css`
        position: fixed;
        z-index: 23;
        overflow-y: scroll;
        transition: 0.3s;
        background-color: ${colors.black10};
        right: ${props.isOpen ? 0 : '-100%'};
        top: 0;
        width: 100%;
        height: 100%;
      `}
      id="mobile-global-navigation-menu"
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <button
          onClick={props.toggleIsOpen}
          css={css`
            background: 0;
            border: 0;
            position: absolute;
            right: 0;
            display: flex;
            padding-block: 16px;
            padding-inline: 16px;
          `}
          data-testid="close"
        >
          <IcomoonIcon
            name="close"
            label="メニューを閉じる"
            css={css`
              color: ${colors.white00};
              font-size: 24px;
            `}
          ></IcomoonIcon>
        </button>
        <p
          css={css`
            margin: 0;
            flex-basis: 100%;
            padding-block: 15px;
            padding-inline-start: 24px;
          `}
        >
          <Link href={logoLink} passHref>
            <a
              target={logoBlank}
              css={css`
                display: inline-block;
              `}
              data-testid="logo"
            >
              <Logo mode="dark" />
            </a>
          </Link>
        </p>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <ul
          css={css`
            margin: 0;
            padding: 0;
            margin-block-start: 24px;
            margin-inline: 40px;
          `}
        >
          <MenuLink href={`${enterpriseUrl}`} toggleIsOpen={props.toggleIsOpen}>
            スピーダとは
          </MenuLink>
          <MenuLink
            href={customerInterviewsUrl}
            toggleIsOpen={props.toggleIsOpen}
          >
            導入事例
          </MenuLink>
          <MenuLink href={seminarsUrl} toggleIsOpen={props.toggleIsOpen}>
            セミナー
          </MenuLink>
          <MenuLink href={resourcesUrl} toggleIsOpen={props.toggleIsOpen}>
            資料・レポート
          </MenuLink>
        </ul>
        <ul
          css={css`
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            grid-row-gap: 16px;
            margin-block-start: 48px;
            margin-inline: 40px;
          `}
        >
          {props.pageType === 'product' ? (
            <>
              <MenuCvButton
                href={downloadDocumentUrl}
                toggleIsOpen={props.toggleIsOpen}
                type="fill"
                external={true}
              >
                資料ダウンロード
              </MenuCvButton>
              <MenuCvButton
                href={loginPath}
                toggleIsOpen={props.toggleIsOpen}
                type="outline"
              >
                ログイン
              </MenuCvButton>
            </>
          ) : (
            <>
              <MenuCvButton
                href={downloadDocumentUrl}
                toggleIsOpen={props.toggleIsOpen}
                type="fill"
                external={true}
              >
                資料ダウンロード
              </MenuCvButton>
              <MenuCvButton
                href={trialRequestUrl}
                toggleIsOpen={props.toggleIsOpen}
                type="outline"
                external={true}
              >
                無料トライアル
              </MenuCvButton>
            </>
          )}
        </ul>
        <SNS
          css={css`
            margin-block-start: 48px;
            justify-content: center;
          `}
        />
      </div>
    </nav>
  )
}

type MenuCvButtonProps = {
  href: string
  type: 'fill' | 'outline'
  external?: boolean
  children: React.ReactNode
  toggleIsOpen: () => void
}

const MenuCvButton: React.FC<MenuCvButtonProps> = (props) => {
  return (
    <li>
      <EnterpriseButton
        href={props.href}
        type={props.type}
        color="white"
        onClick={props.toggleIsOpen}
        css={css`
          width: 100%;
        `}
        external={props.external}
      >
        {props.children}
      </EnterpriseButton>
    </li>
  )
}

type MenuLinkProps = {
  href: string
  children: React.ReactNode
  toggleIsOpen: () => void
}

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  return (
    <li
      css={css`
        border-bottom: 1px solid ${colors.black30};
        text-align: center;
      `}
    >
      <Link href={props.href} passHref>
        <a
          target="_blank"
          css={css`
            display: block;
            padding-block: 16px;
            color: ${colors.textInvert};
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.02em;
          `}
          onClick={props.toggleIsOpen}
        >
          {props.children}
        </a>
      </Link>
    </li>
  )
}
