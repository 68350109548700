import * as React from 'react'
import { IcomoonIcon } from './IcomoonIcon'
import { css } from '@emotion/react'
import { colors } from 'plume-ui'

type Props = {
  className?: string
  size?: number
  color?: string
  active?: boolean
}

export const TwitterIcon: React.FC<Props> = ({
  className,
  size,
  color,
  active = false,
}) => {
  return (
    <IcomoonIcon
      name="x"
      className={className}
      label="X"
      css={css`
        font-size: ${size || 26}px !important;
        color: ${active ? colors.twitter : color ? color : '#ddd'};
      `}
    />
  )
}

export const FacebookIcon: React.FC<Props> = ({
  className,
  size,
  color,
  active = false,
}) => {
  return (
    <IcomoonIcon
      name="facebook"
      className={className}
      label="Facebook"
      css={css`
        font-size: ${size || 26}px !important;
        color: ${active ? colors.facebook : color ? color : '#ddd'};
      `}
    />
  )
}

export const NewspicksIcon: React.FC<Props> = ({
  className,
  size,
  color,
  active = false,
}) => {
  return (
    <IcomoonIcon
      name="newspicks"
      className={className}
      css={css`
        font-size: ${size || 26}px !important;
        color: ${active ? colors.facebook : color ? color : '#ddd'};
      `}
    />
  )
}

export const LinkedinIcon: React.FC<Props> = ({
  className,
  size,
  color,
  active = false,
}) => {
  return (
    <IcomoonIcon
      name="linkedin-square-fill"
      className={className}
      label="LinkedIn"
      css={css`
        font-size: ${size || 26}px !important;
        color: ${active ? colors.facebook : color ? color : '#ddd'};
      `}
    />
  )
}
